<template>
  <div v-if="is_headquarters === 'true'">
    <reason-list></reason-list>
  </div>
</template>
<script>
import { defineAsyncComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useMutations } from '@/utils/mapStore';
export default {
  name: 'set-preferences',
  components: {
    ReasonList: defineAsyncComponent(() => import('@/components/systemPreferences/ReasonList.vue')),
  },
  setup() {
    const is_headquarters = sessionStorage.getItem('is_headquarters');
    const route = useRoute();

    //離開清空vuex
    const RESET = useMutations('systemPreferences', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/system/preferences')) RESET();
      }
    );

    return { is_headquarters };
  },
};
</script>
<style lang="scss" scoped></style>
